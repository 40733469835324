@font-face {
  font-family: "RSU_Regular";
  src: url("assets/fonts/RSU_Regular.ttf");
}

@font-face {
  font-family: "RSU_Bold";
  src: url("assets/fonts/RSU_Bold.ttf");
}

* {
  font-family: "RSU_Regular", "RSU_Bold" !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebebeb;
}

* {
  font-size: 16pt;
  font-family: "RSU_Regular", "RSU_Bold" !important;
}

@media (max-width: 992px) {
  * {
    font-size: 16pt !important;
  }
  .css-1pduc5x-MuiStack-root > .MuiTextField-root {
    min-width: 0px !important;
  }
}

@media (max-width: 440px) {
  * {
    font-size: 14pt !important;
  }
  .css-1pduc5x-MuiStack-root > .MuiTextField-root {
    min-width: 0px !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 50px #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  /* outline: 0px solid #484c51; */
  border-radius: 6px;
}

*.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
  padding: 12px !important;
  margin-right: 0px;
}

.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 10000;
}
